import CommonButton from 'components/common/button';
import styled from 'styled-components';

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DrawerTitle = styled.div`
  width: 100%;
  color: var(--Text-text_default, #393939);
  text-align: center;
  /* Desktop/Extra Large-700 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

export const FileNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const FileName = styled.span`
  color: var(--Text-text_default, #393939);
  /* Desktop/Medium-500 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export const FileSize = styled.span`
  color: var(--Notifications-notif_error, #e65218);
  /* Desktop/Medium-700 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--Borders-border_grey, #dfe4ea);
`;

export const CompressOptionsTitle = styled.div`
  color: var(--Text-text_default, #393939);
  /* Desktop/Large-700 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  margin-top: 24px;
`;

export const CompressOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

export const CompressOption = styled.div<{ selected?: string }>`
  display: flex;
  padding: 18px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 32px;
  border: ${({ selected }) => (selected === 'true' ? '2px solid #4988FC' : '2px solid #DFE4EA')};
  background: #fff;
  cursor: pointer;
  position: relative;
`;

export const CompressOptionTitle = styled.div<{ selected?: string }>`
  color: ${({ selected }) => (selected === 'true' ? '#4988FC' : '#393939')};
  /* Desktop/Extra Large-700 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

export const EstimatedSizeTitle = styled.div<{ selected?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ selected }) => (selected === 'true' ? '#4988FC' : '#757575')};
  /* Desktop/Medium-500 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const EstimatedSize = styled.span<{ selected?: string }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: ${({ selected }) => (selected === 'true' ? '#4988FC' : 'rgba(73, 136, 252, 0.08)')};
  color: ${({ selected }) => (selected === 'true' ? '#fff' : '#393939')};
  /* Desktop/Medium-700 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const CompressOptionDescription = styled.div`
  color: var(--Text-text_default, #393939);
  /* Desktop/Medium-500 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  white-space: pre-wrap;
`;

export const ContinueButton = styled(CommonButton)`
  height: 72px;
  width: 100%;
  margin-top: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
