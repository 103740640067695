import { InternalFileType } from 'helpers/internalFileTypes';
import { EServiceType, IService } from 'ts/interfaces/services/service';

export const converterServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-word',
      funnel: 'pdf_to_word',
      translateKey: 'service_page.pdf_to_word',
      name: t('service_page.pdf_to_word.name'),
      nameKey: 'service_page.pdf_to_word.name',
      from: InternalFileType.PDF,
      to: InternalFileType.DOCX,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-png',
      funnel: 'pdf_to_png',
      translateKey: 'service_page.pdf_to_png',
      name: t('service_page.pdf_to_png.name'),
      nameKey: 'service_page.pdf_to_png.name',
      from: InternalFileType.PDF,
      to: InternalFileType.PNG,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-jpg',
      funnel: 'pdf_to_jpg',
      translateKey: 'service_page.pdf_to_jpg',
      name: t('service_page.pdf_to_jpg.name'),
      nameKey: 'service_page.pdf_to_jpg.name',
      from: InternalFileType.PDF,
      to: InternalFileType.JPG,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-epub',
      funnel: 'pdf_to_epub',
      translateKey: 'service_page.pdf_to_epub',
      name: t('service_page.pdf_to_epub.name'),
      nameKey: 'service_page.pdf_to_epub.name',
      from: InternalFileType.PDF,
      to: InternalFileType.EPUB,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-excel',
      funnel: 'pdf_to_excel',
      translateKey: 'service_page.pdf_to_excel',
      name: t('service_page.pdf_to_excel.name'),
      nameKey: 'service_page.pdf_to_excel.name',
      from: InternalFileType.PDF,
      to: InternalFileType.XLSX,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-pptx',
      funnel: 'pdf_to_pptx',
      translateKey: 'service_page.pdf_to_pptx',
      name: t('service_page.pdf_to_pptx.name'),
      nameKey: 'service_page.pdf_to_pptx.name',
      from: InternalFileType.PDF,
      to: InternalFileType.PPTX,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/word-to-pdf',
      funnel: 'word_to_pdf',
      translateKey: 'service_page.word_to_pdf',
      name: t('service_page.word_to_pdf.name'),
      nameKey: 'service_page.word_to_pdf.name',
      from: InternalFileType.DOCX,
      to: InternalFileType.PDF,
      availableFormats: '.doc, .docx',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/png-to-pdf',
      funnel: 'png_to_pdf',
      translateKey: 'service_page.png_to_pdf',
      name: t('service_page.png_to_pdf.name'),
      nameKey: 'service_page.png_to_pdf.name',
      from: InternalFileType.PNG,
      to: InternalFileType.PDF,
      availableFormats: '.png, .jpeg, .jpg, .webp, .heic, .jfif, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/jpg-to-pdf',
      funnel: 'jpg_to_pdf',
      translateKey: 'service_page.jpg_to_pdf',
      name: t('service_page.jpg_to_pdf.name'),
      nameKey: 'service_page.jpg_to_pdf.name',
      from: InternalFileType.JPG,
      to: InternalFileType.PDF,
      availableFormats: '.jpg, .jpeg, .png, .heic, .webp, .jfif',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/epub-to-pdf',
      funnel: 'epub_to_pdf',
      translateKey: "service_page.epub_to_pdf",
      name: t('service_page.epub_to_pdf.name'),
      nameKey: 'service_page.epub_to_pdf.name',
      from: InternalFileType.EPUB,
      to: InternalFileType.PDF,
      availableFormats: '.epub',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      funnel: 'excel_to_pdf',
      translateKey: 'service_page.excel_to_pdf',
      name: t('service_page.excel_to_pdf.name'),
      nameKey: 'service_page.excel_to_pdf.name',
      from: InternalFileType.XLSX,
      to: InternalFileType.PDF,
      availableFormats: '.xls, .xlsx, .csv, .txt, .rtf, .ods, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      funnel: 'pptx_to_pdf',
      translateKey: 'service_page.pptx_to_pdf',
      name: t('service_page.pptx_to_pdf.name'),
      nameKey: 'service_page.pptx_to_pdf.name',
      from: InternalFileType.PPTX,
      to: InternalFileType.PDF,
      availableFormats: '.pptx, .ppt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      funnel: 'image_to_pdf',
      translateKey: 'service_page.image_to_pdf',
      name: t('service_page.image_to_pdf.name'),
      nameKey: 'service_page.image_to_pdf.name',
      from: 'Image',
      to: InternalFileType.PDF,
      // TODO: Removed unsupported images
      // availableFormats: '.jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
    },
  ];
};
