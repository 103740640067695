import { IPlan } from 'ts/interfaces/plan/plan';

enum EPlanSlug {
  YEARLY_199 = 'lite_pdf_yearly_199_00_no_trial',
  MONTHLY_39_99_WITH_TRIAL_0_95 = 'lite_pdf_monthly_49_95_in_all_countries_with_trial_0_95',
  MONTHLY_39_99_WITH_TRIAL_1_95 = 'lite_pdf_monthly_49_95_in_all_countries_with_trial_1_95',
  MONTHLY_24_95_NO_TRIAL= 'lite_pdf_ monthly_subscription_no_trial_24.95',
}

export const initialPlans: IPlan[] = [
  {
    id: 'f0c7e61e-be8b-4005-b9b9-467944a71dc0',
    translationKey: 'pricing_page.plans_list.0',
    slagName: EPlanSlug.MONTHLY_39_99_WITH_TRIAL_0_95,
    name: 'leader_7_day_95_monthly_4995',
    price: 0.95,
    fullPrice: 49.95,
    currencySymbol: '$',
    currency: 'USD',
  },
  {
    id: '4bd4c63f-caf2-4f50-b8ab-6e4115461cac',
    translationKey: 'pricing_page.plans_list.1',
    slagName: EPlanSlug.MONTHLY_24_95_NO_TRIAL,
    price: 24.95,
    name: 'leader_no_trial_monthly_2495',
    fullPrice: 24.95,
    currency: 'USD',
    currencySymbol: '$',
    isYear: true,
  },
];
