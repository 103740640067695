import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Assets
import CrossIcon from 'assets/common/cross-icon.svg';

// Data
import { toggleModal } from 'data/actions/modals';
import { convertDocumentDataSelector, documentByIdSelector } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';

// Hooks
import { useCustomNavigate } from 'hooks/useCustomNavigate';

// Constants
import { PAGE_LINKS } from 'ts/constants/pageLinks';

// Utils
import { formatBytesSize } from 'utils/formatFileSize';
import { getCompressedSizes } from 'utils/compressionResults';

// Styles
import {
  DrawerContent,
  DrawerTitle,
  FileName,
  FileNameContainer,
  FileSize,
  Divider,
  CompressOptionsTitle,
  CompressOptions,
  CompressOption,
  CompressOptionTitle,
  EstimatedSizeTitle,
  EstimatedSize,
  CompressOptionDescription,
  ContinueButton,
  Header,
  Content,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const compressOptions = [
  {
    title: 'Smallest file',
    estimatedSize: '22.7 KB',
    description: 'Best for email and storage. \nSome image quality loss.',
  },
  {
    title: 'Balanced',
    estimatedSize: '45.4 KB',
    description: 'Ideal for daily use. \nGood mix of size and quality.',
  },
  {
    title: 'Best quality',
    estimatedSize: '68.1 KB',
    description: 'Sharp images, smaller file. \nMinimal quality loss.',
  },
];

const CompressStep: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { handleFileUploadAndCompress } = useSelector(modalOptionsSelector);

  const documentById: any = useSelector(documentByIdSelector());
  const convertDocumentData = useSelector(convertDocumentDataSelector());

  const [selectedOption, setSelectedOption] = useState<string>(compressOptions[1].title);
  const [compressedSizes, setCompressedSizes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_compress_modal_view',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setCompressedSizes(getCompressedSizes(convertDocumentData?.size));
  }, [convertDocumentData]);

  const onClose = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'compress_select_type_close_tap',
      })
    );

    dispatch(toggleModal({ visible: false }));
  };

  const handleContinue = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'compress_select_type_continue_tap',
      })
    );

    if (typeof handleFileUploadAndCompress === 'function') {
      return handleFileUploadAndCompress();
    }

    dispatch(toggleModal({ visible: false }));
    navigate(PAGE_LINKS.PRICING);
  };

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);

    dispatch(
      sendAnalyticEvent({
        event: 'compress_select_type_tap',
        data: {
          type: option,
        },
      })
    );
  };

  return (
    <DrawerContent>
      <Header>
        <img onClick={onClose} src={CrossIcon} alt="cross" />
      </Header>

      <Content>
        <DrawerTitle>Compress file</DrawerTitle>

        <FileNameContainer>
          <FileName>{convertDocumentData?.filename || documentById?.filename}</FileName>
          <FileSize>{formatBytesSize(convertDocumentData?.size || documentById?.size)}</FileSize>
        </FileNameContainer>

        <Divider />

        <CompressOptionsTitle>Choose compress option</CompressOptionsTitle>

        <CompressOptions>
          {compressOptions.map((option, index) => (
            <CompressOption
              selected={`${selectedOption === option.title}`}
              onClick={() => handleSelectOption(option.title)}
              key={option.title}
            >
              <CompressOptionTitle selected={`${selectedOption === option.title}`}>
                {option.title}
              </CompressOptionTitle>
              <EstimatedSizeTitle selected={`${selectedOption === option.title}`}>
                Estimated file size:{' '}
                <EstimatedSize selected={`${selectedOption === option.title}`}>
                  {compressedSizes[index]}
                </EstimatedSize>
              </EstimatedSizeTitle>
              <CompressOptionDescription>{option.description}</CompressOptionDescription>
            </CompressOption>
          ))}
        </CompressOptions>
      </Content>

      <ContinueButton type="primary" onClick={handleContinue}>
        Continue
      </ContinueButton>
    </DrawerContent>
  );
};

export default CompressStep;
