import styled from 'styled-components';
import { CommonTextH5 } from 'components/common/styles';

import progressFileBg from 'assets/modal/progress-file-bg.svg';

export const Container = styled.div`
  width: 436px;
  height: 358px;
  padding: 24px;
  border-radius: 20px;
  gap: 16px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-text_default, #393939);
  text-align: center;

  /* Desktop/Extra Large-700 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin-top: 8px;
`;

export const ProgressValue = styled.span`
  color: var(--Text-text_primary, #4988fc);
  text-align: center;
  /* Desktop/H2-900 */
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px; /* 129.167% */
  margin-bottom: 50px;
`;

export const AnimationIcon = styled.div`
  width: 122px;
  height: 146px;
  margin-bottom: 46px; /* required to properly set spacing to make it similar to design */

  /* remove once animation is rounded in figma and updated */
  border-radius: 20px;
  overflow: hidden;
`;

export const AnimationSteps = styled.div`
  width: 250px;
  height: 108px;
  margin-top: 20px; /* required to properly set spacing to make it similar to design */
`;

export const ProgressValueContainer = styled.div`
  width: 210px;
  height: 218px;
`;

export const ProgressValueBg = styled.div`
  background-image: url(${progressFileBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
`;

export const ProgressFileTriangle = styled.img`
  position: absolute;
  top: 6px;
  left: 0;

  @keyframes move {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(-10px, -10px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  animation-name: move;
  transform: translate(0px, 0px);
  animation: move 2s ease-in-out infinite;
`;
