import { EServiceType, IService } from 'ts/interfaces/services/service';

export const optimizeServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-pdf',
      funnel: 'compress_pdf',
      translateKey: 'service_page.compress_pdf',
      name: t('service_page.compress_pdf.name'),
      nameKey: 'service_page.compress_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
  ];
};
