import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  .ant-drawer-content {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: red;
  }
  .div > .ant-drawer-title {
    color: #ffffff !important;
  }
  .ant-drawer-close {
    height: 76px;
  }

  .ant-drawer-header {
    border-bottom: none !important;
    display: none !important;
  }

  .ant-drawer-body {
    padding: 24px !important;
  }

  @media (max-width: 768px) {
    .ant-drawer-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
