import { StyleProvider } from '@ant-design/cssinjs';
import { FC, lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

/** Actions */
import { createAnonymousUser, getUser, getUserCountry } from 'data/actions/user';
import { getPlans } from 'data/actions/subscriptions';

/** Selectors */
import { getLoadingSelector } from 'data/selectors/loading';
import { isUserAuthenticated } from 'data/selectors/user';

/** Providers */
import GrowthBookOwnProvider from 'providers/growthBookProvider';

/** Components */
import CookiesBar from 'components/cookiesBar';
import ModalsController from 'components/modalsController';

/** Helpers */
import {
  converterServicesList,
  mainPageService,
  optimizeServicesList,
} from 'helpers/services/servicesList';

/** Interfaces */
import { CookiesBarContainer } from 'sections/hero/styles';

/** Hooks */
// import useClarity from 'hooks/useClarity';
import useResetPassword from 'hooks/useResetPassword';

/** Services */
import { Amplitude } from 'services/analytics/amplitude';

/** Use cases */
import { PathLocaleProvider } from 'providers/pathLocaleProvider';
import { IService } from 'ts/interfaces/services/service';
import { getRedirectPath } from 'utils/redirectPath';
import { storeMarketingValues } from 'utils/storeMarketingValues';
import { loadLocalesDayJs } from './i18n';

/** Styles */
import { GlobalStyle } from 'components/common/styles';
import './styles.css';

/** Layouts */
const MainLayout = lazy(() => import('layouts/main'));
const SubscriptionCancelLayout = lazy(() => import('layouts/subscriptionCancel'));

/** Pages */
const NotFoundPage = lazy(() => import('pages/404'));
const AboutUsPage = lazy(() => import('pages/aboutUs'));
const ContactUsPage = lazy(() => import('pages/contactUs'));
const MoneyBackPolicyPage = lazy(() => import('pages/moneyBackPolicy'));
const PrivacyPolicyPage = lazy(() => import('pages/privacyPolicy'));
const SubscriptionTermsPage = lazy(() => import('pages/subscriptionTerms'));
const TermsOfConditionsPage = lazy(() => import('pages/termsOfConditions'));
const CookiePolicyPage = lazy(() => import('pages/cookiesPolicy'));
const RedirectPage = lazy(() => import('pages/redirect'));
const SettingsPage = lazy(() => import('pages/settings'));
const SubCancelConfirmationPage = lazy(() => import('pages/subCancelConfirmation'));
const SubscriptionOfferPage = lazy(() => import('pages/subscriptionOffer'));
const ServicePage = lazy(() => import('pages/service'));
const MainPage = lazy(() => import('pages/main'));
const CheckoutPage = lazy(() => import('pages/checkout'));
const PricingPage = lazy(() => import('pages/pricing'));
const PaymentSuccess = lazy(() => import('pages/paymentSuccess'));
const DashboardPage = lazy(() => import('pages/dashboard'));

const RouterContainer: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const isAuth = useSelector(isUserAuthenticated);

  useResetPassword();

  useEffect(() => {
    Amplitude.init();
    storeMarketingValues();
    loadLocalesDayJs();
  }, []);

  useEffect(() => {
    dispatch(
      getUserCountry((res: any) => {
        setTimeout(() => {
          dispatch(getPlans());
        }, 5000);
      })
    );

    const onFailed = () => {
      dispatch(
        createAnonymousUser(() => {
          dispatch(
            getUserCountry((res: any) => {
              setTimeout(() => {
                dispatch(getPlans());
              }, 5000);
            })
          );
          dispatch(getUser({}));
        })
      );
    };
    dispatch(getUser({ onFailed }));
  }, [dispatch]);

  return (
    <Router>
      <StyleProvider hashPriority="high">
        <PathLocaleProvider>
          <Suspense>
            <Routes>
              <Route path=":language?">
                {/* Authorized user routes */}
                {isAuth && (
                  <>
                    <Route path={`app`} element={<MainLayout />}>
                      <Route index path="dashboard" element={<DashboardPage />} />
                      <Route path="settings" element={<SettingsPage />} />
                      <Route path="subscription-offer" element={<SubscriptionOfferPage />} />
                    </Route>
                    <Route path={`app`} element={<SubscriptionCancelLayout />}>
                      <Route
                        path="subscription-cancel-confirmation"
                        element={<SubCancelConfirmationPage />}
                      />
                    </Route>
                  </>
                )}
                {/* Unauthorized user redirect routes */}
                {!isAuth && !isLoading && (
                  <Route
                    path={`app/*`}
                    element={<Navigate to={`/${getRedirectPath(i18n?.language)}`} replace />}
                  />
                )}
                {/* Start tools list */}
                {converterServicesList(t).map((item: IService, index) => (
                  <Route
                    key={`route-${item.path}-${index + 1}`}
                    path={`${item.path.replace('/', '')}`}
                    element={<ServicePage service={item} />}
                  />
                ))}

                {optimizeServicesList(t).map((item: IService, index) => (
                  <Route
                    key={`route-${item.path}-${index + 1}`}
                    path={`${item.path.replace('/', '')}`}
                    element={<ServicePage service={item} />}
                  />
                ))}

                {/* End tools list */}

                <Route path={``} element={<MainPage service={mainPageService(t)} />} />
                <Route path={'redirect'} element={<RedirectPage />} />
                <Route path={`contact-us`} element={<ContactUsPage />} />
                <Route path={`about-us`} element={<AboutUsPage />} />
                <Route
                  path={`reset-password`}
                  element={<ServicePage service={converterServicesList(t)[0]} />}
                />
                <Route path={`checkout`} element={<CheckoutPage />} />
                <Route path={`checkout/success`} element={<PaymentSuccess />} />
                <Route path={`subscription-terms`} element={<SubscriptionTermsPage />} />
                <Route path={`terms`} element={<TermsOfConditionsPage />} />
                <Route path={`money-back-policy`} element={<MoneyBackPolicyPage />} />
                <Route path={`privacy-policy`} element={<PrivacyPolicyPage />} />
                <Route path={`cookie-policy`} element={<CookiePolicyPage />} />
                <Route path={`pricing`} element={<PricingPage />} />
                {!isLoading && <Route path="*" element={<NotFoundPage />} />}
              </Route>
            </Routes>
          </Suspense>
        </PathLocaleProvider>
        <ModalsController />
        <CookiesBarContainer>
          <CookiesBar />
        </CookiesBarContainer>
      </StyleProvider>
    </Router>
  );
};

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <GrowthBookOwnProvider>
        <RouterContainer />
      </GrowthBookOwnProvider>
    </>
  );
};

export default App;
