import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Data
import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { sendAnalyticEvent } from 'data/actions/analytics';

// Hooks
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';

// Services
import { UploadingModalTypes } from 'services/analytics/events';

// Components
import ModalLayout from '../baseModal';

// Assets
import checkIcon from 'assets/common/icon-check-square.svg';
import fileTriangleMoving from 'assets/modal/file-triangle-moving.svg';

// Styles
import {
  Container,
  ProgressFileTriangle,
  ProgressValue,
  ProgressValueBg,
  ProgressValueContainer,
  StepsContainer,
  StepsItem,
  Title,
} from './styles';

const STEPS = ['global.downloading_step', 'global.converting_step', 'global.securing_step'];

const ProgressFileModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [stage, setStage] = useState(0);
  const downloadProgress = useSelector(uploadProgressSelector());
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 92);

  useEffect(() => {
    dispatch(updateDownloadProgress(progressValue));
  }, [dispatch, progressValue]);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadProgress(0));
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setStage(1), 1200);
    setTimeout(() => setStage(2), 2600);

    setTimeout(() => setStage(3), 4000);
  }, []);

  // useEffect(() => {
  //   const sendProgressEvent = (progressPercent: number) => {
  //     // console.log('progressPercent', progressPercent);

  //     // delete first break point

  //     dispatch(
  //       sendAnalyticEvent({
  //         event: 'file_processing_progress',
  //         data: {
  //           progress_percent: progressPercent,
  //           modal_type: UploadingModalTypes.CONVERT,
  //         },
  //       })
  //     );
  //   };

  //   let breakPoints = [10, 20, 40, 60, 80, 100];
  //   if (breakPoints.includes(Math.floor(downloadProgress))) {
  //     breakPoints = breakPoints.filter((point) => point !== Math.floor(downloadProgress));
  //     sendProgressEvent(Math.floor(downloadProgress));
  //   }
  // }, [downloadProgress, dispatch]);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_processing_modal_view',
        data: {
          modal_type: UploadingModalTypes.CONVERT,
        },
      })
    );
  }, [dispatch]);

  return (
    <ModalLayout>
      <Container>
        <ProgressValueContainer>
          <ProgressValueBg>
            <ProgressFileTriangle src={fileTriangleMoving} alt="progress-file-bg" />
            <ProgressValue>{Math.floor(downloadProgress)}%</ProgressValue>
          </ProgressValueBg>
        </ProgressValueContainer>

        <Title>{t('global.conversion_progress')}...</Title>

        <StepsContainer>
          {STEPS?.map((step: string, index: number) => (
            <StepsItem
              key={`progress-file-modal-step-${index + 1}`}
              style={{
                opacity: stage > index ? 1 : 0,
                transform: stage > index ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              <img src={checkIcon} alt="check" />
              {t(step)}
            </StepsItem>
          ))}
        </StepsContainer>
      </Container>
    </ModalLayout>
  );
};

export default ProgressFileModal;
