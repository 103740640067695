import { formatBytesSize } from "./formatFileSize";

type CompressionLevel = "High" | "Medium" | "Low";

const compressionResults: Record<string, Record<CompressionLevel, number>> = {
  "0-1": { Low: 54.29, Medium: 47.21, High: 40.13 },
  "1-5": { Low: 73.11, Medium: 63.57, High: 54.04 },
  "5-10": { Low: 77.16, Medium: 67.10, High: 57.03 },
  "10-20": { Low: 85.03, Medium: 73.93, High: 62.84 },
  "20-30": { Low: 89.01, Medium: 77.40, High: 65.79 },
  "30-40": { Low: 89.58, Medium: 77.90, High: 66.21 },
  "40-50": { Low: 89.99, Medium: 78.25, High: 66.51 },
  "50-100": { Low: 94.35, Medium: 82.04, High: 69.73 },
  "100+": { Low: 50.74, Medium: 59.69, High: 68.64 },
};

const formatFileSize = (bytes: number): string => {
  return bytes < 1_000_000
    ? `${(bytes / 1_000).toFixed(1)} KB`
    : `${(bytes / 1_000_000).toFixed(1)} MB`;
};

export const getCompressedSizes = (fileSizeBytes: number): string[] => {
  const fileSizeMB = fileSizeBytes / 1_000_000;

  const range = Object.keys(compressionResults).find((key) => {
    const [min, max] = key.split("-").map(Number);
    return max ? fileSizeMB >= min && fileSizeMB <= max : fileSizeMB >= min;
  });

  if (!range) return ["Unknown size"];

  return (["Low", "Medium", "High"] as CompressionLevel[]).map((level) => {
    const percentage = compressionResults[range][level] / 100;
    const compressedSize = fileSizeBytes * (1 - percentage);
    return formatFileSize(compressedSize);
  });
};

export const getCompressedPercentageAndSize = (fileSizeBytes: number, level: CompressionLevel = "High"): { percentage: number, size: string } => {
  const fileSizeMB = fileSizeBytes / 1_000_000;

  const range = Object.keys(compressionResults).find((key) => {
    const [min, max] = key.split("-").map(Number);
    return max ? fileSizeMB >= min && fileSizeMB <= max : fileSizeMB >= min;
  });

  if (!range) return { percentage: 0, size: 'unknown' };

  const percentage = compressionResults[range][level];
  const compressedSize = formatBytesSize(fileSizeBytes * (1 - percentage / 100));
  return { percentage, size: compressedSize };
};