import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer } from 'antd';

// Data
import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';

// Components
import CompressStep from './steps/compress';
import SignUpStep from './steps/auth/sign-up';
import { AuthFormStep } from './steps/auth/types';
import SignIn from './steps/auth/sign-in';
import ForgotPassword from './steps/auth/forgot-password';

// Styles
import { Container } from './styles';
import { isUserAuthenticated } from 'data/selectors/user';
import useDisableScroll from 'hooks/useDisableScroll';
import useKeyDownPress from 'hooks/useKeyPress';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

const CompressPdfDrawer: FC = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const { visible } = useSelector(modalsSelector);

  const [step, setStep] = useState<AuthFormStep>(AuthFormStep['compress']);

  const onClose = () => {
    dispatch(toggleModal({ visible: false }));
  };

  useDisableScroll();
  useKeyDownPress({ targetKey: 'Escape', onKeyPressDown: onClose });
  useCloseModalOnBack();

  useEffect(() => {
    if (!isAuth) {
      setStep(AuthFormStep['sign-up']);
    }
  }, [isAuth]);

  const renderStep = (status: AuthFormStep): ReactElement => {
    switch (status) {
      case AuthFormStep.compress:
        return <CompressStep />;
      case AuthFormStep['sign-up']:
        return <SignUpStep setStep={setStep} />;
      case AuthFormStep['sign-in']:
        return <SignIn setStep={setStep} />;
      case AuthFormStep['forgot-password']:
        return <ForgotPassword setStep={setStep} />;

      default:
        return <CompressStep />;
    }
  };

  return (
    <Container id="compress-pdf-drawer">
      <Drawer
        getContainer={'#compress-pdf-drawer'}
        title={null}
        onClose={onClose}
        open={visible || true}
        width={436}
        placement="right"
      >
        {renderStep(step)}
      </Drawer>
    </Container>
  );
};

export default CompressPdfDrawer;
