import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const CloseIcon = styled.img`
  right: 16px;
  top: 14px;
  cursor: pointer;
`;

export const CloseIconForgotPassword = styled(CloseIcon)`
  position: absolute;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 4px;
`;

export const Container = styled.div`
  gap: 16px;
  border-radius: 20px;
  background: #fff;
  width: 100%;
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
  }

  @media (max-width: 390px) {
    padding: 20px 15px;
    min-height: max-content;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media (max-width: 390px) {
    gap: 10px;
    padding: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  span {
    color: var(--Text-color-tittle, #212e45);
    /* Desktop/Large-600 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-right: 5px;
  }

  a {
    display: flex;
    padding-top: var(--Auto-Layouts-gap_10px, 10px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--Auto-Layouts-gap_4px, 4px);
    cursor: pointer;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-text_default, #393939);
  text-align: center;
  /* Desktop/H3-600 */
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 22px; /* 150% */
  }
`;

export const GoogleButton = styled.button`
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 8px;
  border: var(--Spacing-sytem-spacing-none, 2px) solid var(--Stroke, #dfe4ea);
  background: var(--White, #fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c5e7f;
  /* Body/Med */
  font-style: normal;
  font-weight: 500;

  //styleName: Desktop/Body/Bold;

  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    border-radius: 6px;
    border: 2px solid #dfe4ea;
    background: var(--Blue-10, #ebeeff);
  }
`;

export const LabeledDivider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;

  span {
    margin: 0 10px;
    color: var(--TailGrids-Text-Color, #637381);
    text-align: center;
    /* Body/Med */

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: #dfe4ea;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Description = styled.div`
  align-self: stretch;
  /* Caption/Reg */
  color: var(--Text-text_secondary, #757575);
  text-align: center;

  /* Mobile/XSmall-500 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */

  span,
  a {
    color: var(--Text-text_primary, #4988fc);

    /* Mobile/XSmall-500 */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const Logo = styled.img`
  width: 125px;
  height: 22.694px;
  cursor: pointer;
`;

export const CheckboxComponent = styled.input`
  width: 20px;
  height: 20px;
  border-radius: var(--Spacing-sytem-spacing-xxs, 4px);
  border: var(--Spacing-sytem-spacing-none, 1px) solid var(--Stroke, #dfe4ea);
  background: var(--White-White, #fff);
  cursor: pointer;
  margin-right: 10px;

  &:active {
    scale: 0.95;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    color: var(--Primary-Text-Color, #637381);
    /* Body Medium/Regular */

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
`;

export const ModalContainer = styled.div`
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(11, 16, 44, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForgotPasswordContainer = styled(Container)`
  display: flex;
  padding: 30px 40px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  min-height: 283px;
  min-height: 283px;
  width: 420px;
  position: relative;
`;

export const ForgotDescription = styled.div`
  color: var(--Text-text_default, #393939);
  text-align: center;
  /* Desktop/Medium-500 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const ForgotTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const RightFlex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  width: 100%;
`;

export const SignUpLink = styled.div`
  color: var(--Buttons_Text-primary_default, #4988fc);
  text-align: right;
  /* Desktop/LARGE-UPPER-700 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  width: 100%;
`;
