import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Assets
import closeImagePath from 'assets/close-icon.svg';
import loginIcon from 'assets/auth/icon_login.svg';
// import CommonInputPassword from 'components/common/inputPassword';

// Data
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { sendAnalyticEvent } from 'data/actions/analytics';

// Helpers
import { isEmailValid } from 'helpers/validation';

// Hooks
import useKeyPress from 'hooks/useKeyPress';

// Components
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';

// Interfaces
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

// Styles
import {
  ButtonsContainer,
  CloseIcon,
  CloseIconContainer,
  Container,
  ContentWrapper,
  Description,
  Header,
  InputContainer,
  InputsWrapper,
  SignUpLink,
  Title,
} from './styles';

import { AuthFormStep } from './types';

const SignUp: FC<{ setStep: Dispatch<SetStateAction<AuthFormStep>> }> = ({ setStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const passwordInputRef = useRef<any>();

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ password: '', email: '' });

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'registration_modal_view' }));
  }, [dispatch]);

  // const connectGmailAccount = (e: any) => {
  //   // const url = buildOauthRequestUrl();
  //   // window.location.href = url;
  //   // dispatch(sendAnalyticEvent({ event: 'continue_google_tap' }));
  // };

  const validateEmail = useCallback(
    (email: string) => {
      if (!isEmailValid(email)) {
        setErrors((prev) => ({
          ...prev,
          email: t('global.please_enter_valid_email'),
        }));
        return false;
      }
      setErrors((prev) => ({ ...prev, email: '' }));
      return true;
    },
    [t]
  );

  const handleSignUp = useCallback(() => {
    const onSuccessSignUp = () => {
      dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser()) }));

      dispatch(
        sendAnalyticEvent({
          event: 'email_enter_tap',
          data: { email, type: 'email' },
        })
      );

      setStep(AuthFormStep['compress']);
    };

    const onFailedSignUp = () => {
      dispatch(
        sendAnalyticEvent({
          event: 'email_already_exist_popup',
        })
      );
      openNotification({
        message: (
          <div>
            {t('auth_form.this_email_already_exist')}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
            >
              {' '}
              {t('auth_form.log_in')}
            </span>
            .
          </div>
        ),
        type: ENotification.ERROR,
        duration: 10,
      });
    };

    const isValidEmail = validateEmail(email);
    if (passwordInputRef?.current && isValidEmail) {
      passwordInputRef?.current?.focus();
    }

    if (!isValidEmail) return;
    dispatch(
      sendAnalyticEvent({ event: 'registration_continue_tap', data: { button_state: 'active' } })
    );

    dispatch(createUserReveal({ email, onSuccess: onSuccessSignUp, onFailed: onFailedSignUp }));
  }, [email, dispatch, t, validateEmail, setStep]);

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleSignUp });

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.by_creating_account_means')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('global.terms_and_conditions')}
        </CommonLink>
        , {t('auth_form.and_our')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('global.privacy_policy')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  const topButtons = useMemo(
    () => (
      <CloseIconContainer>
        <CloseIcon
          onClick={() => dispatch(toggleModal({ visible: false }))}
          src={closeImagePath}
          alt=""
        />
      </CloseIconContainer>
    ),
    [dispatch]
  );

  const handleNavigateLogin = useCallback(() => {
    setStep(AuthFormStep['sign-in']);
  }, [setStep]);

  const headerElement = useMemo(
    () => (
      <>
        <Header>
          <span>{t('auth_form.have_an_account')}</span>
          <SignUpLink onClick={handleNavigateLogin}>
            <img src={loginIcon} alt="login-icon" />
            {t('auth_form.log_in')}
          </SignUpLink>
        </Header>

        <Title>{t('auth_form.sign_up')}</Title>
      </>
    ),
    [handleNavigateLogin, t]
  );

  return (
    <Container>
      {topButtons}

      <ContentWrapper>
        {headerElement}
        <InputsWrapper>
          <InputContainer>
            <EmailAutoCompleteInput
              onChange={(value: string) => setEmail(value)}
              value={email}
              placeholder={t('auth_form.email')}
              label={t('auth_form.email_address')}
              error={errors.email}
            />
          </InputContainer>
        </InputsWrapper>

        <ButtonsContainer>
          <CommonButton size="72px" type="primary" onClick={handleSignUp}>
            {t('global.continue')}
          </CommonButton>
        </ButtonsContainer>

        {descriptionElement}
      </ContentWrapper>
    </Container>
  );
};

export default SignUp;
