import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  Store,
  Dispatch,
  compose,
  AnyAction
} from 'redux';

import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import rootReducer from './reducers/rootReducer';
import createApiMiddleware from './middleware/api';
import createAnalyticsMiddleware from './middleware/analytics';


// 🔹 Middleware list
const apiMiddleware = createApiMiddleware();
const analyticsMiddleware = createAnalyticsMiddleware();


// 🔹 Enable dev tools for development environment
const composeWithOptions =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools({ trace: true, maxAge: 100, traceLimit: 200 })
    : compose;

// Function to configure store with initial state
export const configureStore = (initialState: any = {}) => {
  return createStore(
    rootReducer,
    initialState,
    composeWithOptions(applyMiddleware(thunk, apiMiddleware, analyticsMiddleware))
  );
};

// Create store instance with an optional initial state
export const store: Store<any, IReduxAction> & { dispatch: Dispatch } = configureStore();

export const dispatchAction = (action: AnyAction) => {
  store.dispatch(action);
};